import React, { useEffect } from 'react';
import './App.css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { useServiceWorker } from './helpers/useServiceWorker';

// ex. usage of the service worker
// useEffect(() => {
//   if (showReload && waitingWorker) {
//     showToast({
//       description: (
//         <div>
//           A new version of this page is available
//           <button onClick={() => reloadPage()}>REFRESH</button>
//         </div>
//       ),
//     });
//   } else closeToast();
// }, [waitingWorker, showReload, reloadPage]);

// function to get current year
function getYear() {
  return new Date().getFullYear();
}

function openDiscord() {
  window.open('https://discord.gg/cj63B44cPE', '_blank');
}

function openBendroNet() {
  window.open('https://bendrocorp.app', '_blank');
}

function openBendroNetApplication() {
  window.open('https://bendrocorp.app/application', '_blank');
}

function App() {
  // get the use service worker hook
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  // return the main component
  return (
    <main className='relative'>
      <div className="flex justify-center max-w-5xl flex-col mx-auto font-sans">
        {/* header logo */}
        <div className='p-1'>
          <span className="logo">
            <img className='logo' src="/imgs/bendrocorp-final-white.png" alt="BendroCorp Logo" />
            <span className='align-middle logo-text'>
              BENDROCORP
              {/* <p>Serve. Inform. Protect.</p> */}
            </span>
          </span>
          {/* show button if showReload */}
          {showReload && waitingWorker && (
            <button onClick={() => reloadPage()} className="bg-[#2fdf75] hover:bg-[#29c467] text-white font-bold py-2 px-4 my-4 rounded-lg float-right drop-shadow-sm">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
              </svg>
            </button>
          )}
          <button id='top-bendronet-btn' onClick={ openBendroNet } className="bg-[#1576b3] hover:bg-[#1F648F] text-white font-bold py-2 px-4 my-4 rounded-lg float-right  drop-shadow-sm">
            Open BendroNET
          </button>
        </div>
        {/* slider */}
        <div className='drop-shadow-md'>
          <Swiper
            className='rounded-lg'
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            navigation ={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            slidesPerView={1}
            autoplay={{ delay: 6000, disableOnInteraction: false }}
          >
            <SwiperSlide className='swiper-slide'>
              <div className='slide-one'>
                <p className='slide-txt text-white text-xl absolute bottom-4 right-4 font-bold bg-black bg-opacity-50 rounded-lg p-2 no-select'>Working to provide you a home on the Frontier!</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide'>
              <div className='slide-five'>
                <p className='slide-txt text-white text-xl absolute bottom-4 right-4 font-bold bg-black bg-opacity-50 rounded-lg p-2'>
                  <span className='block text-3xl no-select'>BCS Providence</span>
                  <span className='no-select'>Building the frontier for our clients and partners!</span>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide'>
              <div className='slide-two'>
                <p className='slide-txt text-white text-xl absolute bottom-4 right-4 font-bold bg-black bg-opacity-50 rounded-lg p-2 no-select'>Logistical support to meet the needs of your settlement!</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide'>
              <div className='slide-three'>
                <p className='slide-txt text-white text-xl absolute bottom-4 right-4 font-bold text-right bg-black bg-opacity-50 rounded-lg p-2'>
                  <span className='block text-3xl no-select'>DO YOUR PART!</span>
                  <span className='no-select'>Skilled pilots, engineers and frontiersman needed! Join BendroCorp today!</span>
                  {/* Subject apprehension is everyone's responsibility */}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide'>
              <div className='slide-four'>
                <p className='text-white text-xl absolute bottom-4 right-4 font-bold bg-black bg-opacity-50 text-right rounded-lg p-2'>
                  <span className='block text-3xl no-select'>BendroCity Project</span>
                  <span className='no-select'>Creating the premier construction station in Castra.</span>
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* services grid */}
        <div className="grid md:grid-cols-3 sm:grid-cols-1 my-1 p-2 min-h-32 font-sans">
          <div className='rounded-lg bg-slate-500 m-1 p-2 min-h-32 font-sans text-white drop-shadow-md pointer bendronet font-bold relative' onClick={ openBendroNet }>
            <div className='absolute bottom-0 left-0 bg-black bg-opacity-70 p-1 rounded-lg'>
              <div className='bfont'>BendroNET</div>
              <div className='subtext'>Get our mobi app!</div>
            </div>
          </div>
          <div className='rounded-lg bg-slate-500 m-1 p-2 min-h-32 font-sans text-white drop-shadow-md bendrosafe relative'>
            <div className='absolute bottom-0 right-0 bg-black bg-opacity-70 p-1 rounded-lg'>
              <div className='bfont text-right'>BendroSAFE</div>
              <div className='subtext'>Providing unrivaled threat intelligence!</div>
            </div>
          </div>
          <div className='discord-cell rounded-lg bg-slate-500 m-1 p-2 min-h-32 font-sans text-white drop-shadow-md pointer relative' onClick={ openDiscord }>
            <img className='discord-logo absolute top-4 left-4' src="/discord-white.svg" alt="" />
            <p className='font-bold absolute bottom-4 right-8 subtext'>Join us on Discord!</p>
          </div>
        </div>
        {/* who we are */}
        <div className='rounded-lg bg-slate-500 my-1 p-2 min-h-32 font-sans text-white drop-shadow-md grid grid-cols-3'>
          <div className='col-span-3 md:col-span-2 p-4'>
            <h1 className='text-3xl font-bold'>Who are we?</h1>
            <p>Incorporated from a trade co-op in 2943 by the Aayhan family, the goals of BendroCorp have evolved into a two fold enterprise with the singular goal of “Expanding the Frontier…”.</p>
            <br />
            <p>Firstly the expansion of the known frontier for the average civilian or citizen in the UEE. BendroCorp exists to provide cost effective colonial engineering solutions to our customers and partners. From the bridge of the Providence, a CNOU Pioneer, we will rapidly build any outpost for our partners and clients. Our goal is to see independence and expansion for every being.</p>
            <p>Secondly mutual aid, while not a security corporation, BendroCorp and its leadership believe that strength is required in the verse’. BendroCorp actively partners with the Bounty Hunter’s Guild and the UEE Advocacy to report, assess and if required assist in the apprehension of violent fugitives. We work to diligently connect higher risk clients with partners and service that we utilize with the goal of ensuring your prosperity on the frontier.</p>
          </div>
          <div className='hidden md:block'>
            <img src="/imgs/industrial-people1.png" alt="" />
          </div>
        </div>
        {/* recruiting */}
        <div className='rounded-lg bg-slate-500 my-1 p-2 min-h-72 font-sans text-white drop-shadow-md recruiting'>
          <div className='p-4 bg-black bg-opacity-60 rounded-lg recruiting-inner'>
            <h1 className='text-3xl font-bold text-center'>Do you want to make a difference?</h1>
            <p className='text-center'>Are you on industrial pioneer? Does the thrill of operating and industrializing the frontier excite you?</p>
            <p className='text-center'>Then come join us as we will rapidly build the frontier and ensure independence and expansion for every being.</p>
            <div className='h-full flex flex-col items-center'>
              <button onClick={ openBendroNetApplication } className="text-2xl bg-[#1576b3] hover:bg-[#1F648F] text-white font-bold py-2 px-4 my-3 rounded-lg p-4  drop-shadow-sm">
                Fill Out An Application Today!
              </button>
            </div>
          </div>
        </div>
        {/* partners */}
        <div className='partner-bar rounded-lg bg-slate-500 my-4 p-2 min-h-32 font-sans text-white drop-shadow-md'>
          <h1 className='text-3xl font-bold p-4'>
            Our Partners
          </h1>
          <div className='grid md:grid-cols-4 sm:grid-cols-1'>
            <div className='text-center'>
              <a href="https://robertsspaceindustries.com/galactapedia/article/VDoP5pmODk-advocacy" target='_blank' rel="noreferrer">
                <img className='mx-auto' src="/imgs/partner-advocacy.png" alt="" />
              </a>
            </div>
            <div className='text-center'>
              <a href="https://robertsspaceindustries.com/galactapedia/article/0OaBkLLG1N-crusader-industries" target='_blank' rel="noreferrer">
                <img className='mx-auto' src="/imgs/partner-crusader.png" alt="" />
              </a>
            </div>
            <div className='text-center'>
              <a href="https://robertsspaceindustries.com/galactapedia/article/0QxYZvWY7N-clark-defense-systems" target='_blank' rel="noreferrer">
                <img className='mx-auto' src="/imgs/partner-clarkd.png" alt="" />
              </a>
            </div>
            <div className='text-center'>
              <a href="https://robertsspaceindustries.com/galactapedia/article/0QxYZvWY7N-clark-defense-systems" target='_blank' rel="noreferrer">
                <img className='mx-auto' src="/imgs/partner-volt.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
        {/* footer */}
        <footer className='grid grid-cols-6 place-items-end py-2'>
          <div>
            <a href="https://robertsspaceindustries.com/" target='_blank' rel="noreferrer">
              <img className='mbtc-logo' src="/imgs/MadeByTheCommunity_White.png" alt="" />
            </a>
          </div>
          <div className='text-white col-span-5 p-2 text-right'>
            <p>
              <a className='text-[#fff] hover:text-[#1576b3]' href="https://robertsspaceindustries.com/" target='_blank' rel="noreferrer">Star Citizen</a>&nbsp;|&nbsp;<a className='text-[#fff] hover:text-[#1576b3]' href="https://robertsspaceindustries.com/orgs/BENDROCORP" target='_blank' rel="noreferrer">Organization</a>&nbsp;|&nbsp;<a className='text-[#fff] hover:text-[#1576b3]' href="https://discord.gg/cj63B44cPE" target='_blank' rel="noreferrer">Discord</a>
            </p>
            <p className='text-xs'>Copyright &copy;{getYear()} BendroCorp. All Rights Reserved.</p>
            <p className='text-xs'>BendroCorp is a fictional player organization for the game Star Citizen and does not offer real life services.</p>
          </div>
        </footer>
      </div>
    </main>
  );
}

export default App;
